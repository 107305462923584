import { LettureType } from "src/actions";
import {
  getLetturaPerMatricola,
  getTipoEdistribuzioneFromTipoContatore,
  LetturaMensile,
} from "energix-types/src/utils/edistribuzione.utils";
import { useMemo } from "react";
import { TipoContatore } from "energix-types/src/Contatore";
import numeral from "numeral";
import { Button, Typography } from "@mui/material";
import { ArrowLeftIcon } from "src/components/elements/AppIcons";
import { Input } from "src/components/old/reactstrapFix/reactstrap";
import moment from "moment";

type DatiFornitoreLetturaCellProps = {
  anno: number;
  letture: LettureType;
  lettureGiaInserite: { [key: string]: number | null } | null | undefined;
  matricola: string;
  tipoContatore: TipoContatore;
  meseKey: string;
  meseKeys: string[];
  k: number | null;
  numeroCifreIntere: number | null;
  numeroCifreDecimali: number | null;
  usaValoreSuggerito?: (v: number) => void;
};

export function DatiFornitoreLetturaCell({
  letture: _letture,
  anno,
  lettureGiaInserite,
  matricola,
  tipoContatore,
  meseKey,
  meseKeys,
  k,
  numeroCifreIntere,
  numeroCifreDecimali,
  usaValoreSuggerito,
}: DatiFornitoreLetturaCellProps) {
  const lettureByMeseKey = useLettureByMeseKey(
    _letture,
    matricola || "",
    tipoContatore,
    meseKeys,
    lettureGiaInserite,
    k
  );

  const letturaObj = lettureByMeseKey[meseKey];

  // const letturaObj = useMemo(() => {
  //   const tipoContatoreEdistribuzione =
  //     getTipoContatoreEdistribuzione(tipoContatore);

  //   if (!tipoContatoreEdistribuzione) {
  //     return null;
  //   }

  //   const annoMese = getAnnoMeseByMeseKey(meseKey);
  //   if (!annoMese) {
  //     return null;
  //   }

  //   const v = getLetturaEDeltaByMatricola(
  //     _letture,
  //     anno,
  //     annoMese[1],
  //     matricola,
  //     tipoContatoreEdistribuzione,
  //     lettureGiaInserite[meseKey]
  //   );

  //   return v;

  //   // return getLetturePerMatricola(
  //   //   _letture,
  //   //   matricola,
  //   //   tipoContatore,
  //   //   lettureGiaInserite,
  //   //   k
  //   // );
  // }, [_letture, matricola, tipoContatore, lettureGiaInserite, k]);

  console.log("letturaObj", letturaObj);

  const shouldNotBeSet = letturaMeseKeyShouldNotBeSet(
    anno,
    meseKey,
    lettureGiaInserite
  );

  return (
    <>
      {letturaObj ? (
        <>
          {usaValoreSuggerito && (
            <Button
              title="Usa questo valore"
              variant="outlined"
              size="small"
              sx={{ p: "3px", minWidth: 0, mr: "8px !important" }}
              onClick={() => {
                const letturaString = formatLettura(
                  letturaObj.letturaFinaleKwh!,
                  k,
                  numeroCifreIntere,
                  numeroCifreDecimali
                );
                const v = numeral(letturaString).value();
                if (v) {
                  usaValoreSuggerito?.(v);
                }
              }}
              disabled={
                shouldNotBeSet ||
                typeof letturaObj.letturaFinaleKwh !== "number"
              }
            >
              <ArrowLeftIcon />
            </Button>
          )}

          {typeof letturaObj.letturaFinaleKwh === "number" && (
            <span>
              {formatLettura(
                letturaObj.letturaFinaleKwh,
                k,
                numeroCifreIntere,
                numeroCifreDecimali
              )}
            </span>
          )}
          {typeof letturaObj.deltaKwh === "number" && (
            <span style={{ fontSize: "0.9em" }}>
              ({Math.round(letturaObj.deltaKwh)} kWh
              {/* {typeof letturaObj.lettura !== "number" && (
                <em style={{ fontSize: "0.8em" }}>
                  {" "}
                  Necessaria lettura mese precedente
                </em>
              )} */}
              )
            </span>
          )}

          {shouldNotBeSet && (
            <span
              style={{
                fontSize: 9,
                display: "inline-block",
                maxWidth: 100,
                marginLeft: 15,
              }}
            >
              Attenzione: Questa lettura deve corrispondere a quella inserita
              nella dichiarazione dell'anno precedente
            </span>
          )}
        </>
      ) : (
        "-"
      )}
      {/* <pre style={{ maxHeight: 100, overflowY: "auto" }}>
        {meseKey}
        <br />
        {lettura}
        <br />
        {JSON.stringify(letture, null, 2)}
      </pre> */}
    </>
  );
}

type DatiFornitoreLetturaCellUsaTuttiIMesiProps = {
  anno: number;
  letture: LettureType;
  lettureGiaInserite: { [key: string]: number | null } | null | undefined;
  matricola: string;
  matricolaEdistribuzione: string | null;
  setMatricolaEdistribuzione: (matr: string | null) => void;
  tipoContatore: TipoContatore;
  meseKeys: string[];
  k: number | null;
  numeroCifreIntere: number | null;
  numeroCifreDecimali: number | null;
  usaValoriSuggeriti?: (v: { [key: string]: number }) => void;
};

function useLettureByMeseKey(
  letture: LettureType,
  matricola: string,
  tipoContatore: TipoContatore,
  meseKeys: string[],
  lettureGiaInserite: { [key: string]: number | null } | null | undefined,
  k: number | null
) {
  const lettureByMeseKey = useMemo(() => {
    const lettureByMeseKey: { [meseKey: string]: LetturaMensile } = {};
    const tipoContatoreEdistribuzione =
      getTipoEdistribuzioneFromTipoContatore(tipoContatore);

    if (!tipoContatoreEdistribuzione) {
      return lettureByMeseKey;
    }

    for (const meseKey of meseKeys) {
      const annoMese = getAnnoMeseByMeseKey(meseKey);
      const mesePrecKey = getMeseKeyMesePrec(meseKey);

      let anno: number | null = null;
      let mese: number | null = null;
      let giorno: number | null = null;

      if (annoMese) {
        [anno, mese] = annoMese;
      }
      if (annoMese === null) {
        // non è l'inizio del mese, ma un giorno intermedio
        const parsed = getAnnoMeseGiornoByMeseKey(meseKey);
        if (parsed) {
          [anno, mese, giorno] = parsed;
        }
      }

      if (anno !== null && mese !== null) {
        let lettPrecedente: number | null = null;
        if (mesePrecKey) {
          lettPrecedente = lettureGiaInserite?.[mesePrecKey] ?? null;
          if (lettPrecedente) {
            if (k) {
              lettPrecedente = lettPrecedente * k;
            }
          } else {
            lettPrecedente = lettureByMeseKey[mesePrecKey]?.letturaFinaleKwh;
          }
        }

        const v = getLetturaPerMatricola(
          letture,
          anno,
          mese,
          giorno,
          matricola,
          tipoContatoreEdistribuzione,
          lettPrecedente
        );
        if (v) {
          lettureByMeseKey[meseKey] = v;
        }
      }
    }
    return lettureByMeseKey;
  }, [letture, matricola, tipoContatore, lettureGiaInserite, meseKeys, k]);
  return lettureByMeseKey;
}

export function DatiFornitoreLetturaCellUsaTuttiIMesi({
  letture: _letture,
  anno,
  lettureGiaInserite,
  matricola,
  matricolaEdistribuzione,
  setMatricolaEdistribuzione,
  tipoContatore,
  meseKeys,
  k,
  numeroCifreIntere,
  numeroCifreDecimali,
  usaValoriSuggeriti,
}: DatiFornitoreLetturaCellUsaTuttiIMesiProps) {
  const lettureByMeseKey = useLettureByMeseKey(
    _letture,
    matricola || matricolaEdistribuzione || "",
    tipoContatore,
    meseKeys,
    lettureGiaInserite,
    k
  );

  console.log("lettureByMeseKey", lettureByMeseKey);

  return (
    <>
      {!lettureByMeseKey || !!matricolaEdistribuzione ? (
        // non ho trovato la matricola...
        <>
          <div>Matricola "{matricola}" non trovata</div>
          {Object.keys(_letture.letture).length > 0 && (
            <>
              <Typography variant="caption">
                Scegli il contatore E-Distribuzione dall'elenco:
              </Typography>
              <Input
                type="select"
                value={matricolaEdistribuzione || ""}
                onChange={(e: any) => {
                  setMatricolaEdistribuzione?.(e.target.value || null);
                }}
                style={{ marginBottom: 10 }}
              >
                <option value=""></option>
                {Object.keys(_letture.letture).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                })}
              </Input>
            </>
          )}
        </>
      ) : null}

      {!lettureByMeseKey &&
      !!matricolaEdistribuzione &&
      typeof lettureByMeseKey === "object" &&
      Object.keys(lettureByMeseKey).length === 0 ? (
        <>
          <span>Nessun valore disponibile</span>
        </>
      ) : null}

      {typeof lettureByMeseKey === "object" &&
      Object.keys(lettureByMeseKey).length > 0 ? (
        <>
          {usaValoriSuggeriti && (
            <Button
              variant="outlined"
              size="small"
              sx={{ p: "3px", minWidth: 0, mr: "8px !important" }}
              onClick={() => {
                const obj: { [key: string]: number } = {};
                for (const key in lettureByMeseKey) {
                  if (
                    Object.prototype.hasOwnProperty.call(lettureByMeseKey, key)
                  ) {
                    const lettura = lettureByMeseKey[key];

                    if (lettura?.letturaFinaleKwh) {
                      const letturaString = formatLettura(
                        lettura?.letturaFinaleKwh,
                        k,
                        numeroCifreIntere,
                        numeroCifreDecimali
                      );
                      const v = numeral(letturaString).value();
                      if (v) {
                        obj[key] = v;
                      }
                    }
                  }
                }
                if (Object.keys(obj).length > 0) {
                  usaValoriSuggeriti?.(obj);
                }
              }}
            >
              <ArrowLeftIcon />
            </Button>
          )}

          <span>Usa tutti i valori suggeriti</span>
        </>
      ) : null}
    </>
  );
}

type LetturePerMeseResult = {
  [data: string]: {
    lettura: number | null;
    kwh: number | null;
  };
};

function searchLetturaObjForMese(
  letture: LetturePerMeseResult | null,
  meseKey: string,
  lettureGiaInserite: { [key: string]: number | null },
  k: number | null
): {
  lettura: number | null;
  kwh: number | null;
} | null {
  if (!letture) {
    return null;
  }
  const meseKeyFineMesePrec: string | null = getMeseKeyFineMesePrec(meseKey);
  let fineMesePrecFound = false;
  const keys = Object.keys(letture).sort();
  let key = null;
  let keyPrecedente = null;
  for (let index = 0; index < keys.length; index++) {
    const k = keys[index];
    if (k <= meseKey) {
      if (!fineMesePrecFound) {
        key = k;
        keyPrecedente = keys[index - 1] ?? null;
      }
      if (meseKeyFineMesePrec && meseKeyFineMesePrec === k) {
        fineMesePrecFound = true;
      }
    } else {
      break;
    }
  }
  const obj = key ? letture[key] : null;

  if (!obj) {
    return null;
  }

  // function getKeyLetturaPrecedente(key: string): string | null {
  //   const keys2 = Object.keys(lettureGiaInserite).sort();
  //   const index = keys2.indexOf(key);
  //   if (index > 0) {
  //     return keys2[index - 1];
  //   }
  //   return null;
  // }

  // if (k && typeof obj.lettura !== "number" && typeof obj.kwh === "number") {
  //   const objPrec = keyPrecedente ? letture[keyPrecedente] : null;
  //   const lettPrec = objPrec?.lettura;
  //   if (typeof lettPrec === "number") {
  //     const delta = obj.kwh;
  //     return {
  //       lettura: lettPrec + delta,
  //       kwh: obj.kwh,
  //     };
  //   } else {
  //     const meseKeyMesePrec = key && getKeyLetturaPrecedente(key); // getMeseKeyMesePrec(meseKey);
  //     let lettPrec = meseKeyMesePrec && lettureGiaInserite[meseKeyMesePrec];
  //     if (typeof lettPrec === "number") {
  //       // in realtà  lettura deve essere in kwh
  //       lettPrec = lettPrec * k;
  //       const delta = obj.kwh;
  //       return {
  //         lettura: lettPrec + delta,
  //         kwh: obj.kwh,
  //       };
  //     }
  //   }
  // }

  return obj;
}

const getMeseKeyFineMesePrecRegexp = /L([0-9]{4})([0-9]{2})([0-9]{2})/;
function getMeseKeyFineMesePrec(meseKey: string): string | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const d = parseInt(match[3]);
  if (d === 1) {
    const x = moment(`${match[1]}-${match[2]}-${match[3]}`).subtract(1, "day");
    return "L" + x.format("YYYYMMDD");
  }
  return null;
}

function getMeseKeyMesePrec(meseKey: string): string | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const y = parseInt(match[1]);
  const m = parseInt(match[2]);
  const d = parseInt(match[3]);
  if (d === 1) {
    const x = moment(`${y}-${m}-${d}`).subtract(1, "month");
    return "L" + x.format("YYYYMMDD");
  }
  return null;
}

function getAnnoMeseGiornoByMeseKey(
  meseKey: string
): [number, number, number] | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const y = parseInt(match[1]);
  const m = parseInt(match[2]);
  const d = parseInt(match[3]);
  return [y, m, d];
}

function getAnnoMeseByMeseKey(meseKey: string): [number, number] | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const d = parseInt(match[3]);
  if (d === 1) {
    const x = moment(`${match[1]}-${match[2]}-${match[3]}`).subtract(1, "day");
    return [x.year(), x.month() + 1];
  }
  return null;
}

const zeroString = "00000000000000000000000000000000000000000000000000";

function formatLettura(
  value: number,
  k: number | null,
  numeroCifreIntere: number | null,
  numeroCifreDecimali: number | null
) {
  k = k || 1;

  value = value / k;

  numeroCifreDecimali = numeroCifreDecimali
    ? Math.min(numeroCifreDecimali, 50)
    : 0;

  let decimalPart: string;
  if (numeroCifreDecimali === 0) {
    decimalPart = "";
  } else {
    decimalPart = "." + zeroString.substring(0, numeroCifreDecimali);
  }

  if (numeroCifreIntere && value > 0) {
    let max = parseInt("1" + zeroString.substring(0, numeroCifreIntere), 0);
    value = value % max;
  }

  const format = "0,0" + decimalPart;
  return numeral(value).format(format);
}

function letturaMeseKeyShouldNotBeSet(
  anno: number,
  meseKey: string,
  lettureGiaInserite: { [key: string]: number | null } | null | undefined
) {
  if (
    meseKey &&
    meseKey.startsWith("L" + (anno - 1)) &&
    meseKey.substring(5) === "0101"
  ) {
    return lettureGiaInserite && typeof lettureGiaInserite[meseKey] === "number"
      ? true
      : false;
  }
  return false;
}
