import { moduliAttiviByKey } from "./ModuliAttivi";
import { mapValues } from "lodash";

export interface CostoOperazioni {
  servizioBase: number;
  invioDichiarazioneAd1: number;
  addestramento: number;
  ticketSingolo: number;
  mailingAutomatico: number;
  scadenze: number;
  dashboardUtenteFinale: number;
  serviziMassivi: number;
  prelievoDatiDalGestoreDiReteSingoloImpianto: number;
  monitoraggioEdistribuzione: number;
  monitoraggioGse: number;
  reportEdistribuzione: number;
  reportGSE: number;
  alertEdistribuzione: number;
  registroLetture: number;
  delegaDogane: number;
}

export type CostoOperazione = {
  id: number;
  operazione: keyof CostoOperazioni;
  valore: number;
};

export const operazioniLabelByCostoKey: {
  [key in keyof CostoOperazioni]: string;
} = {
  invioDichiarazioneAd1: "Invio dichiarazione AD1",
  ticketSingolo: "Ticket di supporto",
  servizioBase: "Iscrizione annuale",
  prelievoDatiDalGestoreDiReteSingoloImpianto:
    "Prelievo dei dati di produzione ed immissione dal Gestore di Rete",
  addestramento: "Addestramento",
  delegaDogane: "Generazione codice per Software Delega Dogane",
  ...mapValues(moduliAttiviByKey, (value) => value.label),
};

export const operazioniLabel = Object.entries(operazioniLabelByCostoKey).map(
  ([key, value]) => ({ key: key as keyof CostoOperazioni, label: value })
);

export const numeroMassimoTicketSupportoAPagamento = 6;
export const numeroMassimoReportEdistribuzione = 100;
