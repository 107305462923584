import moment from "moment";
import { TipoContatore } from "energix-types/src/Contatore";
import { TipoEDistribuzioneEnergiaMensile } from "../edistribuzione";

export type PodMapping = {
  [pod: string]: {
    [matricola: string]: {
      da: string;
      a: string;
    };
  };
};

export type LetturaObj = {
  //YYYY-MM-DD
  [data: string]: {
    f1: number;
    f2: number;
    f3: number;
    total: number;
  };
};

export type LettureMatricola = {
  [matricola: string]: {
    passiva?: LetturaObj;
    immessa?: LetturaObj;
    prodotta?: LetturaObj;
    prelevata?: LetturaObj;
  };
};

export type CurveDiCarico = {
  [pod: string]: {
    mensili: {
      prelevata?: {
        [annoMese: string]: number;
      };
      immessa?: {
        [annoMese: string]: number;
      };
    };
    giornalieri: {
      prelevata?: {
        [annoMeseGiorno: string]: number;
      };
      immessa?: {
        [annoMeseGiorno: string]: number;
      };
    };
    quartorari: {
      prelevata?: {
        [annoMeseGiorno: string]: { [quartorario: number]: number | undefined };
      };
      immessa?: {
        [annoMeseGiorno: string]: { [quartorario: number]: number | undefined };
      };
    };
  };
};

export type ParsedData = {
  podMapping: PodMapping;
  letture: LettureMatricola;
  curveDiCarico: CurveDiCarico;
};

export function getMatricola(
  podMapping: PodMapping,
  pod: string,
  data: string,
  currentTotal?: number,
  prevTotal?: number
): string | null {
  const objs = podMapping?.[pod] || {};
  const matricole = Object.keys(objs);
  if (!matricole.length) {
    return null;
  }
  if (matricole.length === 1) {
    return matricole[0];
  }
  const inRange = matricole
    .filter((m) => {
      const obj = objs[m];
      return data >= obj.da && data <= obj.a;
    })
    .sort((a, b) => {
      return objs[a].da < objs[b].da ? -1 : 1;
    });

  if (!inRange.length) {
    return null;
  }

  if (inRange.length === 1) {
    return inRange[0];
  }

  // considero solo le prime due
  if (typeof currentTotal === "number" && typeof prevTotal === "number") {
    if (currentTotal >= prevTotal) {
      return inRange[0];
    } else {
      return inRange[1];
    }
  }
  return inRange[0];
}

export function getTipoEdistribuzioneFromTipoContatore(
  tipoContatore: TipoContatore
) {
  return tipoContatore === "produzione"
    ? "produzione"
    : tipoContatore === "ceduta"
    ? "immissione"
    : tipoContatore === "ricevuta"
    ? "prelievo"
    : null;
}

export function getTipoEdistribuzioneDaTipoCurvaDiCarico(
  pod: string,
  tipoCurvaDiCarico: "immessa" | "prelevata"
): TipoEDistribuzioneEnergiaMensile | null {
  const isProduzione = pod && pod.startsWith("ITP") ? true : false;

  if (tipoCurvaDiCarico === "immessa") {
    return isProduzione ? "produzione" : "immissione";
  } else if (tipoCurvaDiCarico === "prelevata") {
    return isProduzione ? null : "prelievo";
  }

  return null;
}

export function getMinMaxDati(data: ParsedData): {
  min: [number, number];
  max: [number, number];
} | null {
  let minAnno: number | null = null;
  let minMese: number | null = null;
  let maxAnno: number | null = null;
  let maxMese: number | null = null;

  // letture
  let lettureMin: string | null = null;
  let lettureMax: string | null = null;
  for (const matricola of Object.keys(data.letture)) {
    const findMinMax = (tipo: keyof typeof data.letture[string]) => {
      const letture = data.letture[matricola]?.[tipo] ?? {};
      for (const data of Object.keys(letture)) {
        if (!lettureMin || data < lettureMin) {
          lettureMin = data;
        }

        if (!lettureMax || data > lettureMax) {
          lettureMax = data;
        }
      }
    };

    findMinMax("passiva");
    findMinMax("immessa");
    findMinMax("prodotta");
    findMinMax("prelevata");
  }
  if (lettureMin) {
    const d = moment(lettureMin, "YYYY-MM-DD");
    minAnno = d.year();
    minMese = d.month() + 1;
  }

  if (lettureMax) {
    const d = moment(lettureMax, "YYYY-MM-DD");
    maxAnno = d.year();
    maxMese = d.month() + 1;
  }

  // curve di carico
  let curveMin = "";
  let curveMax = "";
  for (const pod of Object.keys(data.curveDiCarico)) {
    const findMinMax = (
      tipo: keyof typeof data.curveDiCarico[string]["mensili"]
    ) => {
      const letture = data.curveDiCarico[pod]?.mensili?.[tipo] ?? {};
      for (const data of Object.keys(letture)) {
        if (!curveMin || data < curveMin) {
          curveMin = data;
        }

        if (!curveMax || data > curveMax) {
          curveMax = data;
        }
      }
    };

    findMinMax("prelevata");
    findMinMax("immessa");
  }

  if (curveMin) {
    const [anno, mese] = curveMin.split("-").map((x) => parseInt(x));
    if (
      !minAnno ||
      !minMese ||
      anno < minAnno ||
      (anno === minAnno && mese < minMese)
    ) {
      minAnno = anno;
      minMese = mese;
    }
  }
  if (curveMax) {
    const [anno, mese] = curveMax.split("-").map((x) => parseInt(x));
    if (
      !maxAnno ||
      !maxMese ||
      anno > maxAnno ||
      (anno === maxAnno && mese > maxMese)
    ) {
      maxAnno = anno;
      maxMese = mese;
    }
  }

  if (minAnno && minMese && maxAnno && maxMese) {
    return {
      min: [minAnno, minMese],
      max: [maxAnno, maxMese],
    };
  } else {
    return null;
  }
}

export type LetturaMensile = {
  pod: string;
  matricola: string | null;
  letturaFinaleKwh: number | null;
  deltaKwh: number | null;
};

export function getLetturaPerMatricola(
  data: ParsedData,
  anno: number,
  mese: number,
  giorno: number | null, // null => fine del mese
  matricola: string,
  contatoreTipo: "produzione" | "immissione" | "prelievo",
  letturaPrecedenteKwh: number | null
): LetturaMensile | null {
  const fromLetture = _getLettureAndDeltaFromLetture(
    data,
    anno,
    mese,
    giorno,
    null,
    matricola,
    contatoreTipo,
    letturaPrecedenteKwh
  );
  if (fromLetture) {
    return fromLetture;
  }

  if (giorno == null) {
    const fromCurveDiCarico = _getLettureAndDeltaFromCurveDiCarico(
      data,
      anno,
      mese,
      null,
      matricola,
      contatoreTipo,
      letturaPrecedenteKwh
    );
    if (fromCurveDiCarico) {
      return fromCurveDiCarico;
    }
  }

  return null;
}

export function getDeltaPerPod(
  data: ParsedData,
  anno: number,
  mese: number,
  pod: string,
  contatoreTipo: "produzione" | "immissione" | "prelievo",
  letturaPrecedenteKwh: number | null
): LetturaMensile | null {
  const fromCurveDiCarico = _getLettureAndDeltaFromCurveDiCarico(
    data,
    anno,
    mese,
    pod,
    null,
    contatoreTipo,
    letturaPrecedenteKwh
  );
  if (fromCurveDiCarico) {
    return fromCurveDiCarico;
  }

  const fromLetture = _getLettureAndDeltaFromLetture(
    data,
    anno,
    mese,
    null,
    pod,
    null,
    contatoreTipo,
    letturaPrecedenteKwh
  );
  if (fromLetture) {
    return fromLetture;
  }

  return null;
}

function _getLettureAndDeltaFromLetture(
  data: ParsedData,
  anno: number,
  mese: number,
  giorno: number | null, // null => fine del mese
  pod: string | null,
  matricola: string | null,
  contatoreTipo: "produzione" | "immissione" | "prelievo",
  letturaPrecedenteKwh: number | null
): LetturaMensile | null {
  let matricole: string[];

  if (pod) {
    const mapping = data.podMapping[pod];
    matricole = Object.keys(mapping);
  } else if (matricola) {
    matricole = Object.keys(data.letture).filter((x) => {
      return (
        typeof x === "string" && (x === matricola || x.endsWith(matricola))
      );
    });
  } else {
    throw new Error("'pod' o 'matricola' sono obbligatori");
  }

  if (
    !pod &&
    matricole.length !== 1 &&
    typeof letturaPrecedenteKwh === "number" &&
    matricola
  ) {
    return null;
    // const lettura = _getLettureAndDeltaFromCurveDiCaricoPerMatricola(
    //   data,
    //   matricola,
    //   contatoreTipo,
    //   anno,
    //   mese,
    //   letturaPrecedente
    // );
    // if (lettura) {
    //   return [lettura];
    // } else {
    //   return null;
    // }
  }

  const letture = data.letture[matricole[0]];

  const lettureKey =
    contatoreTipo === "produzione"
      ? "prodotta"
      : contatoreTipo === "immissione"
      ? "immessa"
      : // contatoreTipo === "prelievo"
        "prelevata";

  let dateKey: string;
  if (giorno) {
    dateKey = moment()
      .year(anno)
      .month(mese - 1)
      .date(giorno)
      .format("YYYY-MM-DD");
  } else {
    dateKey = moment()
      .year(anno)
      .month(mese - 1)
      .startOf("month")
      .add(1, "month")
      .format("YYYY-MM-DD");
  }

  const letturaObj = letture?.[lettureKey]?.[dateKey] ?? null;

  if (letturaObj && typeof letturaObj.total === "number") {
    const pod = Object.entries(data.podMapping).find((entry) => {
      const mapping = entry[1];
      const matricole = Object.keys(mapping);
      return matricole.some(
        (x) => x === matricole[0] || x.endsWith(matricole[0])
      );
    })?.[0];

    if (!pod) {
      // non dovrebbe mai succedere
      return null;
    }

    return {
      pod: pod,
      matricola: matricole[0],
      deltaKwh: letturaPrecedenteKwh
        ? letturaObj.total - letturaPrecedenteKwh
        : null,
      letturaFinaleKwh: letturaObj.total,
    };
  } else {
    // const letture: LetturaMensile[] = [];

    // for (const m of matricole) {
    //   const l = _getLettureAndDeltaFromCurveDiCaricoPerMatricola(
    //     data,
    //     m,
    //     contatoreTipo,
    //     anno,
    //     mese,
    //     letturaPrecedente
    //   );
    //   if (l) {
    //     letture.push(l);
    //   }
    // }

    // return letture;
    return null;
  }
}

function _getLettureAndDeltaFromCurveDiCarico(
  data: ParsedData,
  anno: number,
  mese: number,
  _pod: string | null,
  matricola: string | null,
  contatoreTipo: "produzione" | "immissione" | "prelievo",
  letturaPrecedenteKwh: number | null
): LetturaMensile | null {
  let pods: string[];

  if (matricola) {
    pods = Object.keys(data.curveDiCarico).filter((x) => {
      const podToMatricola = data.podMapping[x];
      return podToMatricola
        ? Object.keys(podToMatricola).some(
            (x2) =>
              typeof x2 === "string" &&
              (x2 === matricola || x2.endsWith(matricola))
          )
        : false;
    });

    if (pods.length !== 1) {
      return null;
    }
  } else if (_pod) {
    pods = [_pod];
  } else {
    throw new Error("'pod' o 'matricola' sono obbligatori");
  }

  const pod = pods[0];

  const isProduzione = pod.startsWith("ITP") ? true : false;
  const curveDiCarico = data.curveDiCarico[pods[0]]?.mensili || {};

  let curveDiCaricoKey: keyof typeof curveDiCarico;

  if (isProduzione && contatoreTipo === "produzione") {
    curveDiCaricoKey = "immessa";
  } else if (!isProduzione && contatoreTipo === "immissione") {
    curveDiCaricoKey = "immessa";
  } else if (!isProduzione && contatoreTipo === "prelievo") {
    curveDiCaricoKey = "prelevata";
  } else {
    return null;
  }

  const curveDiCaricoObj =
    curveDiCarico[
      curveDiCaricoKey as keyof Omit<typeof curveDiCarico, "prelevata">
    ] ?? null;

  if (curveDiCaricoObj) {
    const key = `${anno}-${mese.toString().padStart(2, "0")}`;
    const delta = curveDiCaricoObj[key];

    if (delta) {
      return {
        pod: pod,
        matricola: matricola,
        letturaFinaleKwh:
          letturaPrecedenteKwh !== null ? letturaPrecedenteKwh + delta : null,
        deltaKwh: delta,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
}
